import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "bulma/css/bulma.css"
import "../styles/promocje.scss"
import Footer from "../components/footer"
import _navigation from "../components/_navigation"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ArrowBack from "@material-ui/icons/ArrowBack"

const Polecamy = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPolecamy {
        edges {
          node {
            tytul
            childContentfulPolecamyTrescRichTextNode {
              json
            }
            zdjecie {
              resize {
                src
              }
            }
          }
        }
      }
    }
  `)
  const options = {
    renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }
  return (
    <div className="promocje-main-container">
      <_navigation />
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">Polecamy</h1>
          </div>
        </div>
      </section>
      <div className="container is-flex-column ">
        {data.allContentfulPolecamy.edges.map(polecane => (
          <div className="single-promocja-wrapper columns">
            <img
              alt="polecamy grafika"
              className="promocja-fota column is-3-widescreen is-hidden-mobile"
              style={{ maxHeight: "300px" }}
              src={polecane.node.zdjecie.resize.src}
            />
            <div className="content-promocja-wrapper is-7 column is-offset-1">
              <span className="single-promocja-tytul">{polecane.node.tytul}</span>
              <span>
                {documentToReactComponents(polecane.node.childContentfulPolecamyTrescRichTextNode.json, options)}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="button-wrapper-wstecz">
        <Link to={"/"}>
          <button className="button button-wstecz-promocje is-primary is-light">
            <ArrowBack /> Wstecz
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  )
}
export default Polecamy
